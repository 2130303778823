<template>
  <v-container fluid>
    <V-row>
      <v-col md="12">
        <SettingItem
          @loadData="loadData"
          collection="shipping_package"
          :items="shipping_package"
          :itemHeader="couponItemHeader"
        />
      </v-col>
    </V-row>
  </v-container>
</template>

<script>
import SettingItem from "./SettingItem/index";
import ApiService from "@/services/api.service";

export default {
  components: {
    SettingItem,
  },
  data() {
    return {
      shipping_package: [],
      couponItemHeader: [
        { text: "width", value: "width" },
        { text: "height", value: "height" },
        { text: "length", value: "length" },
        { text: "from", value: "from" },
        { text: "to", value: "to" },
        { text: "height_mes", value: "height_mes" },
        { text: "weight", value: "weight" },
        { text: "weight_mes", value: "weight_mes" },
        { text: "Actions", value: "actions", sortable: false },
      ],
    };
  },
  computed: {},
  methods: {
    async loadData() {
      try {
        this.loading = true;
        this.fetchData();
        this.loading = false;
      } catch (error) {
        console.log(error);
      }
    },
    async fetchData() {
      try {
        const data = await ApiService.get(
          "items/shipping_package?fields=id,width,height,length,from,to,height_mes,weight,weight_mes"
        );
        this.shipping_package = data.data.data;
        console.log(this.shipping_package);
      } catch (error) {
        console.log(error);
      }
    },
  },
  mounted() {
    this.fetchData(undefined);
  },
};
</script>

<style></style>
